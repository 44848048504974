<template>
  <div class="resolve_plan_container">
    <div class="topImg">
      <img src="../assets/image/resolvePlan/top.png" alt="">
    </div>
    <div class="des">
      <div v-for="( item, index ) in  resolvePlanData " :class="{ item: true, f2_active: tabIndex === index }"
        @click="changeItem(index)">
        <span>{{ item.title }}</span>
      </div>
    </div>
    <!-- 轮播图内容 -->
    <div class="main">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index ) in resolvePlanData" :key="item.name">
            <div class="floor1">
              <div class="title">
                {{ mainContent.floor1.title }}
              </div>
              <div class="list-container">
                <div class="list-item" :class="[mainContent.floor1.listData.length <= 4 ? 'newlist-item' : '']"
                  v-for="list  in mainContent.floor1.listData">
                  <img :src="list.bg" v-if="list.bg" class="list-bg">
                  <div class="text">
                    <div class="text-1">{{ list.text1 }}</div>
                    <div class="text-2">{{ list.text2 }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="floor2" v-if="mainContent.name === '食品生鲜' || mainContent.name === '美妆日化'"
              :class="{ 'floor2-2': mainContent.floor2.listData.length > 4 }">
              <div class="bg">
                <img :src="mainContent.floor2.bg">
              </div>
              <div class="container">
                <div class="title">
                  {{ mainContent.floor2.title }}
                </div>
                <div class="list-container">
                  <div class="list-item" v-for="list in mainContent.floor2.listData">
                    <div class="left"> <img :src="list.icon"> </div>
                    <div class="right">
                      <div class="text1"> {{ list.text1 }} </div>
                      <div class="text2"> {{ list.text2 }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="floor2 newfloor2" v-else :class="{ 'floor2-2': mainContent.floor2.listData.length > 4 }">
              <div class="container">
                <div class="title">
                  {{ mainContent.floor2.title }}
                </div>
                <div class="img">
                  <img :src="mainContent.floor2.programme">
                </div>
                <div class="newlist-container">
                  <div class="list-item" v-for="list in mainContent.floor2.listData">
                    <div class="left"> <img :src="list.icon"> </div>
                    <div class="right">
                      <div class="text1"> {{ list.text1 }} </div>
                      <div class="text2"> {{ list.text2 }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            <div class="floor3" v-if="mainContent.name === '食品生鲜' || mainContent.name === '美妆日化'">
              <div class="container">
                <div class="title">
                  {{ mainContent.floor3.title }}
                </div>
                <div class="list-container">
                  <div class="list-item" v-for="list in  mainContent.floor3.listData "
                    :class="{ 'list-item-tow': mainContent.floor3.listData.length >= 6 }">
                    <div class="top"> <img :src="list.icon"> </div>
                    <div class="text">
                      <div class="text1"> {{ list.text1 }} </div>
                      <div class="text2"> {{ list.text2 }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="floor4" v-if="mainContent.name === '食品生鲜' || mainContent.name === '美妆日化'">
              <div class="container">
                <div class="title">
                  {{ mainContent.floor4.title }}
                </div>
                <div class="list-container">
                  <div class="left"> <img :src="mainContent.floor4.imgSrc"> </div>
                  <div class="right">
                    <div class="text1"> {{ mainContent.floor4.text1 }} </div>
                    <div class="msg-box msg-box1">
                      <div class=" msg-item" v-for=" msg  in  mainContent.floor4.msgBoxList ">
                        <div class="label">{{ msg.label }}</div>
                        <div class="text" v-if="msg.content">{{ msg.content }}</div>
                        <div class="msg-list-item-container-1" v-if="msg.type === '1'">
                          <div class="msg-list-item" v-for=" subMsg  in    msg.listData  ">
                            <div class="top"> {{ subMsg.text1 }}</div>
                            <div class="bottom">
                              <div class="text1"> {{ subMsg.text2 }} </div>
                              <div class="icon" v-if="subMsg.icon"> <img :src="subMsg.icon"> </div>
                            </div>
                          </div>
                        </div>
                        <div class="msg-list-item-container-2" v-if="msg.type === '2'">
                          <div class="msg-list-item" v-for=" subMsg  in    msg.listData  ">
                            <div class="top">
                              <img :src="subMsg.icon">
                            </div>
                            <div class="bottom">
                              <div class="text1"> {{ subMsg.text }} </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="floor6" v-else>
              <div class="container">
                <div class="title">
                  {{ mainContent.floor6.title }}
                </div>
                <div class="body">
                  <div class="topbox"></div>
                  <div class="bottombox"></div>
                  <div class="bagc">
                    <div class="containerbox" v-for="(item, index) in mainContent.floor6.projectlist" :key="index">
                      <div v-if="acticeinedx === index">
                        <div class="img">
                          <img :src="item.img">
                        </div>
                        <div class="titlehead">{{ item.title }}</div>
                        <div class="line"></div>
                        <div class="maintext">{{ item.info }}</div>
                        <div class="fenye">
                          <div class="before" @click="beforeactive"> <img src="../assets/image/resolvePlan/before.png">
                          </div>
                          <div class="next" @click="nextactive"> <img src="../assets/image/resolvePlan/next.png"></div>
                        </div>
                        <div class="activepage">
                          0{{ acticeinedx + 1 }}/0{{ mainContent.floor6.projectlist.length }}
                        </div>
                        <div class="list">
                          <div class="indexline1"></div>
                          <div class="indexline2"></div>
                          <div class="indexlist" v-for="(item, index) in 3"
                            :class="[acticeinedx === index ? 'activeindex' : '']"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="floor5">
              <div class="item item1"> 下载行业解决方案 </div>
              <div class="item item2">感谢您对九州云智的关注，如您对我们的产品感兴趣，请联系我们400-800-4488转84888</div>
              <div class="item item3" @click="downLoad"> 立即下载 </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <downDialog ref="downDialog" />
  </div>
</template>
<script>
import { resolvePlanData } from '../assets/config/resolvePlan.js'
import downDialog from '../components/downDialog.vue'
export default {
  name: 'resolvePlan',
  components: {
    downDialog,
  },
  data () {
    return {
      resolvePlanData,
      tabIndex: 0,
      timer: null,
      acticeinedx: 0,
    }
  },
  computed: {
    mainContent () {
      return this.resolvePlanData[this.tabIndex].mainContent
    }
  },
  watch: {
    '$route.params.id' (newValue) {
      this.tabIndex = +newValue - 1
    }
  },
  methods: {
    nextactive () {
      if (this.acticeinedx === 2) {
        this.acticeinedx = 0
      } else {
        this.acticeinedx += 1
      }
    },
    beforeactive () {
      if (this.acticeinedx === 0) {
        this.acticeinedx = 2
      } else {
        this.acticeinedx -= 1
      }
    },
    //轮播图
    initSwiper () {
      // this.swiper = new Swiper(".swiper-container", {
      //   loop: true,
      //   preventClicks: false,
      //   loopedSlides: null,
      //   spaceBetween: 0,
      //   centeredSlides: true,
      //   pagination: ".swiper-pagination",
      //   // slidesPerView: "auto",
      //   onSlideChangeEnd: (swiper) => {
      //     this.swiperActiveIndex = swiper.activeIndex
      //     if (this.swiperActiveIndex > this.resolvePlanData.length) {
      //       this.swiperActiveIndex = this.swiperActiveIndex - this.resolvePlanData.length
      //     }
      //     if (this.swiperActiveIndex < 1) {
      //       this.swiperActiveIndex = this.swiperActiveIndex + this.resolvePlanData.length
      //     }
      //     this.tabIndex = this.swiperActiveIndex - 1
      //   },
      // })
    },
    //title点击切换
    changeItem (index) {
      this.tabIndex = index
      // this.swiper.slideTo(this.tabIndex + 1)
    },
    downLoad () {
      this.$refs.downDialog.open(this.tabIndex)
    }
  },
  mounted () {
    // this.timer = setInterval(() => {
    //   this.nextactive()
    // }, 3000)
    this.initSwiper()
    this.$nextTick(() => {
      this.tabIndex = +this.$route.params.id - 1
      // this.swiper.slideTo(this.tabIndex + 1)
    })
  },
  beforeactive () {
    // this.timer = null
    // clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}

.resolve_plan_container {

  /* margin-top: 1.02rem; */
  .topImg {
    height: 6rem;
    width: 100%;
  }

  .des {
    position: relative;
    z-index: 2;
    display: flex;
    height: 0.9rem;
    background: #FFFFFF;
    opacity: 1;
    align-items: center;
    box-shadow: 0 .02rem .1rem 0px rgba(197, 221, 255, 0.5);

    .item {
      cursor: pointer;
      font-size: .24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #0A121F;
      line-height: 0.9rem;
      text-align: center;
      border-bottom: 0.04rem solid transparent;

      &.f2_active {
        border-bottom: 0.04rem solid #1562ec;
      }
    }

    .item:nth-child(1) {
      // margin-left: 0.96rem
      margin-left: 2.2rem
    }

    .item:nth-child(n + 2) {
      // margin-left: 1.76rem;
      margin-left: 2.5rem;
    }
  }

  .main {
    .swiper-slide {
      .floor1 {
        padding: 0 2.6rem;

        .title {
          margin-top: .7rem;
          margin-bottom: 0.4rem;
          font-size: .4rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0A121F;
          text-align: center;
        }

        .list-container {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          align-items: flex-start;

          .list-item {
            position: relative;
            width: 4.26rem;
            height: 1.2rem;
            background: #EBEFF5;
            margin-bottom: .3rem;
            margin-right: .6rem;
            border-radius: .06rem;
            cursor: pointer;

            .list-bg {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              visibility: hidden;
            }

            .text {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 99;
              color: #0A121F;
              font-size: .2rem;
              line-height: .3rem;
              padding: 0 .33rem;
              text-align: center;
            }

            &:hover .list-bg {
              visibility: visible;
            }

            &:hover .text {
              color: #0560D6
            }

            &:nth-child(3n) {
              margin-right: 0;
            }

            &:nth-last-child(-n+3) {
              margin-bottom: 0;
            }
          }

          .newlist-item {
            width: 6.5rem;
            margin-right: 1rem !important;
          }

          .newlist-item:nth-child(2n) {
            margin-right: 0rem !important;
          }
        }
      }

      .floor2 {
        margin-top: .7rem;
        width: 100%;
        position: relative;

        .bg {
          height: 4.78rem;
          position: absolute;
        }

        .container {
          // position: absolute;
          position: relative;
          left: 0;
          top: 0;
          z-index: 1;
          padding: .7rem 2.6rem;

          .title {
            font-size: .4rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .47rem;
            text-align: center;
            margin-bottom: .4rem;
          }

          .list-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-between;

            .list-item {
              width: 5.77rem;
              display: flex;
              color: #FFFFFF;
              line-height: 0.25rem;
              margin-bottom: .7rem;

              .left {
                max-width: .81rem;
                min-width: .81rem;
                max-height: .81rem;
                min-height: .81rem;
                margin-right: .15rem;
              }

              .right {
                .text1 {
                  height: .27rem;
                  font-size: .2rem;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #FFFFFF;
                  line-height: .27rem;
                  margin-bottom: .15rem;
                }
              }

              &:nth-child(2n) {
                margin-right: 0;
              }

            }
          }

        }
      }

      .newfloor2 {
        .container {
          position: relative;
          height: auto;
          background-image: url('../assets/image/resolvePlan/f2_bg_1new.png');
          background-size: 100% 100%;

          .newlist-container {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-around;
            margin-top: .4rem;

            .list-item {
              position: relative;
              width: 3rem;
              height: 3.5rem;
              display: flex;
              flex-direction: column;

              .left {
                img {
                  width: 100%;
                  height: 2.3rem;
                }
              }

              .right {
                background: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;

                .text1 {
                  height: .5rem;
                  font-size: .25rem;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 800;
                  color: #0A121F;
                  line-height: .5rem;
                }

                .text2 {
                  font-size: .16rem;
                  font-family: PingFang SC-Medium, PingFang SC;
                  height: .5rem;
                  line-height: .3rem;
                  color: #0A121F;
                }
              }

              &:nth-child(2n) {
                margin-right: 0;
              }

            }
          }

          .title {
            color: #000;
          }
        }
      }

      .floor2-2 {
        .bg {
          height: 6.34rem !important;
        }

        .list-item {
          margin-bottom: .4rem !important;
        }
      }

      .floor3 {
        margin-top: .7rem;
        margin-bottom: .7rem;
        width: 100%;

        .container {
          padding: 0 2.6rem;

          .title {
            height: .56rem;
            font-size: .4rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0A121F;
            line-height: .47rem;
            text-align: center;
            margin-top: .4rem;
            margin-bottom: .4rem;
          }

          .list-container {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-around;


            .list-item {
              /* width: 3.3rem; */
              width: 22%;
              height: 2.16rem;
              background: #FFFFFF;
              box-shadow: .11rem .09rem .47rem -0.14rem rgba(27, 86, 201, 0.26);
              border-radius: .1rem;
              opacity: 1;
              padding: .38rem 0.24rem;
              /* margin-right: .26rem; */
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              margin-bottom: .3rem;

              .top {
                width: .65rem;
                height: 65px;
                margin-bottom: 20px;
              }

              .text {
                font-size: .16rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #0A121F;
                line-height: .28rem;
              }
            }

            .list-item-tow {
              width: 28%;
            }
          }

        }
      }

      .floor4 {
        height: 7.58rem;
        padding-top: 0.7rem;
        background: url("../assets/image/resolvePlan/f4_bg.png") no-repeat;

        .container {
          padding: 0 2.6rem;
        }

        .title {
          height: .56rem;
          font-size: .4rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0A121F;
          line-height: .47rem;
          text-align: center;
          margin-bottom: .4rem;
        }

        .list-container {
          display: flex;
          background-color: #fff;

          .left {
            min-width: 5.66rem;
            min-height: 5.22rem;
            max-width: 5.66rem;
            max-height: 5.22rem;
            margin-right: .68rem;
          }

          .right {
            padding-top: .4rem;

            .text1 {
              height: .42rem;
              font-size: .32rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #0A121F;
              line-height: .42rem;
            }

            .msg-box {
              .label {
                margin-top: .3rem;
                margin-bottom: .2rem;
                height: .21rem;
                font-size: .2rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #0560D6;
                line-height: .21rem;
              }

              .text {
                width: 7.25rem;
                font-size: .16rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #565E66;
                line-height: .25rem;
              }

              .msg-list-item-container-1 {
                display: flex;

                .msg-list-item {
                  margin-right: .48rem;

                  .top {
                    height: .27rem;
                    font-size: .36rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #565E66;
                    line-height: .27rem;
                  }

                  .bottom {
                    display: flex;
                    align-items: center;

                    .text1 {
                      font-size: .14rem;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #565E66;
                      margin-right: .06rem;
                    }

                    .icon {
                      height: .17rem;
                      width: .17rem;
                    }
                  }
                }

              }

              .msg-list-item-container-2 {
                display: flex;


                .msg-list-item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 1.2rem;
                  margin-right: .2rem;

                  .top {
                    width: .56rem;
                    height: .56rem;
                  }

                  .bottom {
                    .text1 {
                      height: .23rem;
                      font-size: .14rem;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #565E66;
                      line-height: .27rem;
                    }
                  }
                }
              }
            }
          }
        }

      }

      .floor6 {
        margin-bottom: .7rem;

        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .title {
            margin: .7rem 0 .4rem;
            font-size: .4rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0A121F;
            text-align: center;
          }

          .body {
            height: 5rem;
            width: 71%;
            position: relative;

            .topbox {
              width: .8rem;
              height: .8rem;
              background-color: #e6e9ef;
              position: absolute;
              left: 0rem;
              top: 0rem;
            }

            .bottombox {
              width: 3rem;
              height: .8rem;
              background-color: #0164f6;
              position: absolute;
              left: 0rem;
              bottom: 0rem;
            }

            .bagc {
              height: 100%;
              width: 66%;
              position: absolute;
              right: 0;
              top: 0;
              background-color: #f5f7fa;
            }

            .containerbox {
              position: absolute;
              height: 100%;
              width: 100%;
              padding: .3rem 1.25rem;

              .img {
                width: 5rem;
                height: 4.4rem;
                position: absolute;
                left: -48%;
                top: 50%;
                transform: translate(0%, -50%);

                img {
                  background-size: cover;
                }
              }

              .titlehead {
                font-size: .4rem;
                font-weight: 800;
                color: #0A121F;
                height: .7rem;
                line-height: .7rem;
              }

              .line {
                width: .8rem;
                height: .5vh;
                background-color: #0068ff;
                margin: .1rem 0 .3rem 0;
              }

              .maintext {
                font-size: .21rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #0A121F;
                line-height: .4rem;
              }

              .activepage {
                font-size: .24rem;
                line-height: .5rem;
                color: #fff;
                padding: 0 .1rem;
                background-color: #0164f6;
                position: absolute;
                top: .9rem;
                left: .24rem;
              }

              .fenye {
                position: absolute;
                bottom: .3rem;
                left: 1.2rem;
                display: flex;
                width: .8rem;
                justify-content: space-between;

                div {
                  z-index: 99;
                  cursor: pointer;
                }

                img {
                  width: .2rem;
                  height: .2rem;
                  background-size: cover;
                }
              }

              .list {
                display: flex;
                position: absolute;
                right: 1rem;
                bottom: .3rem;
              }

              .indexlist {
                width: .16rem;
                height: .16rem;
                border-radius: 50%;
                border: 1px solid #000;
                margin-right: .3rem;
              }

              .activeindex {
                background-color: #000;
              }

              .indexline1,
              .indexline2 {
                width: .3rem;
                height: 2px;
                position: absolute;
                top: .07rem;
                left: .16rem;
                background-color: #000;
              }

              .indexline2 {
                left: .62rem;
              }
            }
          }
        }

      }

      .floor5 {
        padding-top: .7rem;
        height: 2.98rem;
        background: url('../assets/image/resolvePlan/f5_bg.png') no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;

        .item1 {
          height: .21rem;
          font-size: .32rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: .21rem;
          margin-bottom: .24rem;
          text-align: center;
        }

        .item2 {
          height: .28rem;
          font-size: .2rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .28rem;
          margin-bottom: .3rem;
          text-align: center;
        }

        .item3 {
          color: #FFFFFF;
          width: 1.71rem;
          height: .54rem;
          line-height: .54rem;
          border-radius: .08rem;
          opacity: 1;
          text-align: center;
          border: .01rem solid #FFFFFF;
          cursor: pointer;
        }

        .item3:hover {
          background-color: #fff;
          color: #0560D6;
        }
      }
    }
  }
}
</style>
