<template>
  <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose" class="down-dialog"
    :close-on-click-modal="false">
    <div class="bg">
      <img src="../assets/image/resolvePlan/downDialog-bg.png" alt="">
    </div>
    <div class="title"> 提交信息以获取资料 </div>
    <el-form :model="formData" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="left">
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入您的企业名称"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formData.name" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item label="职位" prop="name">
        <el-select v-model="formData.region" placeholder="请选择您的职位">
          <el-option label="总经理" value="总经理"></el-option>
          <el-option label="总监" value="总监"></el-option>
          <el-option label="销售经理" value="销售经理"></el-option>
          <el-option label="技术人员" value="技术人员"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="formData.phone" placeholder="请输入您的联系电话"></el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="name">
        <div class="message-box">
          <el-input v-model="formData.name" placeholder="请输入您的短信验证码"></el-input>
          <div class="btn" @click="getCode">
            <div class="text time" v-if="codeTimeFlag"> {{ codeTime }} 秒</div>
            <div class="text" v-else> 获取验证码 </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div type="primary" @click="confirm" class="confirm-btn">提交</div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: 'downDialog',
  data () {
    return {
      limitCodeTime: 60,
      dialogVisible: false,
      codeTime: 60,
      codeTimeFlag: false,
      validateCode: '',// 验证码的验证码
      formData: {
        name: '',
        region: '',
        phone: '',
        type: [],
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    beforeClose () {
      this.codeTimeFlag = false
      this.codeTime = this.limitCodeTime
      this.tabIndex = null
      this.validateCode = ''
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.dialogVisible = false
    },
    open (index) {
      this.dialogVisible = true
      this.tabIndex = index
    },
    confirm () {
    },
    startCodeTime () {
      this.timer = setInterval(() => {
        this.codeTime = this.codeTime - 1
        if (this.codeTime > 0) {
          this.codeTimeFlag = true
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.codeTime = this.limitCodeTime
          this.codeTimeFlag = false
          this.validateCode = ''
        }
      }, 1000)
    },
    //     POST 172.31.58.11:7888/tdp/msg/smsVerify/send //发送验证码
    // {
    //     "bizType":"test", //业务类型 前端写死  bizType+to判定能不能重发
    //     "templateId":1, //模版ID 前端写死
    //     "to":"15827156231", //手机号
    //     "verifyType":"", //NUMBER 纯数字 ENGLISH纯英文 MIX混合 不传默认数字
    //     "verifyLength":"", //验证码长度 4~32 默认6
    //     "verifyValidSecond":"", //验证码有效期(秒) 1分钟~24小时 默认5分钟
    //     "verifyResendSecond":"", //验证码重发周期(秒) 1分钟~24小时 默认1分钟
    // }
    // //错误信息 "请勿频繁发送,xx秒后再试
    checkTelephone (telephone) {
      var reg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!reg.test(telephone)) {
        return false
      } else {
        return true
      }
    },

    async getCode () {
      if (this.codeTimeFlag) return
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }

      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }

      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/send',
        data: {
          bizType: 'resolvePlan',
          "templateId": 1,
          verifyLength: 4,
          "to": this.formData.phone,
          verifyValidSecond: this.limitCodeTime,
          verifyResendSecond: this.limitCodeTime,
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      this.$message.success('验证码发送成功')
      //   开始计时
      this.startCodeTime()
      this.validateCode = res.data
    }
  },
}
</script>
<style lang="scss">
.down-dialog {
  .el-dialog {
    width: 5.72rem !important;
    max-width: 5.72rem !important;
    min-width: 5.72rem !important;

    .title {
      text-align: center;
      height: .39rem;
      font-size: .28rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #0A121F;
      line-height: .33rem;
      margin-bottom: 0.3rem;
    }

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        z-index: 10;
      }
    }

    .el-dialog__body {
      padding: .6rem .8rem;
      position: relative;
      /* background: url('../assets/image/resolvePlan/downDialog-bg.png'); */

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }


      .el-form-item {
        display: flex;
        flex-direction: column;
        /* margin-bottom: 0.15rem; */

        .confirm-btn {
          color: #FFFFFF;
          height: .47rem;
          background: #0560D6;
          border-radius: .04rem;
          opacity: 1;
          text-align: center;
          line-height: .47rem;
          cursor: pointer;
        }

        .message-box {
          display: flex;
          align-items: center;

          .btn {
            min-width: 1.2rem;
            max-width: 1.2rem;
            white-space: nowrap;
            height: .47rem;
            background: #FFFFFF;
            border-radius: .04rem;
            opacity: 1;
            border: .01rem solid #0560D6;
            cursor: pointer;
            margin-left: .06rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              height: .21rem;
              font-size: .14rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #0560D6;
              line-height: .21rem;
              text-align: center;

              &.time {
                /* color: orange; */
                font-size: .2rem;
              }
            }
          }

        }

        .el-form-item__label {
          height: .21rem;
          font-size: .16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #616972;
          line-height: .21rem;
          margin-bottom: 0.06rem;
        }

        .el-form-item__content {
          margin-left: 0 !important;

          .el-input__inner {
            height: 0.47rem;
          }

          .el-select {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
